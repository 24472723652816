<template>
  <header-component></header-component>
  <main id="main" class="main-items-list" v-if="data">
    <project-item
      v-for="project in data.projects"
      :key="project.id"
      :project="project"
    ></project-item>
  </main>
  <footer class="main-footer">
    <div></div>
    <div class="main-footer__author">
      Masterskaya® is an interdisciplinary design team, bringing together experts from all over the world. It has been in operation since 1990.
    </div>
  </footer>
</template>

<style>
  @import './assets/styles/style.css';
</style>

<script>
import Header from './components/Header';
import ProjectItem from './components/ProjectItem';
import { useClient, useQuery } from 'villus';

const STRAPI_API = process.env.VUE_APP_STRAPI_API;
const GRAPHQL_API = `${STRAPI_API}/graphql`;

export default {
  name: 'App',
  components: {
    'header-component': Header,
    'project-item': ProjectItem
  },

  data () {
    return {
      projects: [],
      error: null
    }
  },

  setup () {
    useClient({
      url: GRAPHQL_API
    });

    const { data } = useQuery({
      query: `{
        projects (sort: "date:desc") {
          title
          type
          date
          size
          url
          name
          description
        }
      }`
    });

    return { data };
  }
}
</script>
