<template>
<header class="main-header">
  <div>
    <div class="main-header__wrapper">
      <div class="main-header__section">
        <div class="main-header__logo">
          <div class="main-header__logo--desktop" v-html="require('!html-loader!./../assets/images/masterskaya-logo.svg')"></div>
          <div class="main-header__logo--mobile" v-html="require('!html-loader!./../assets/images/masterskaya-logo-mobile.svg')"></div>
        </div>
      </div>
      <div class="main-header__section">
        <ul class="main-header__nav">
          <li class="main-header__nav-item">
            <a href="https://www.designworkout.ru/" target="_blank">
              Designworkout
            </a>
          </li>
          <li class="main-header__nav-item">
            <a
              href="https://dimabarbanel.com/"
              target="_blank"
              >
              Dima Barbanel
            </a>
          </li>
          <li class="main-header__nav-item">
            <a href="mailto: ask@masterskaya.pro">Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>
</template>

<script>
export default {
  name: 'Header',
}
</script>
