<template>
<section
  :class="['main-item', modifier ? `main-item--${modifier}` : '']"
  @click="open"
  v-observe-visibility="{
    callback: preloadAnimation,
    intersection: {
      rootMargin: '100% 0px 100% 0px',
      once: true,
      throttle: 100
    }
  }"
>
  <div class="main-item__wrapper">
    <div>
      <div class="main-item__animation-wrapper"
        v-observe-visibility="{
          callback: toggleAnimation,
          intersection: {
            rootMargin: '-30% 0px -30% 0px',
            throttle: 100
          }
        }"
      >
        <div class="main-item__animation" ref="animation"></div>
      </div>
    </div>

    <div class="main-item__description" v-if="split">
      <div></div>
      <div :class="`main-item__description--${modifier}`"
        v-html="project.description"></div>
    </div>
    <div class="main-item__description" v-else v-html="project.description"></div>
  </div>
</section>
</template>

<script>
import lottie from 'lottie-web';
import axios from 'axios';

const STRAPI_API = process.env.VUE_APP_STRAPI_API;
const PROJECTS_API = `${STRAPI_API}/projects`;

const modifiers = {
  full: 'wide',
  half: 'mid',
  quarter: 'mid',
  white: 'white',
  black: ''
};

export default {
  name: 'ProjectItem',
  props: {
    project: Object
  },

  data () {
    return {
      playing: false,
      status: undefined,
      visible: false
    };
  },

  methods: {
    preloadAnimation(visible) {
      if (visible) this.loadAnimation();
      else this.unloadAnimation();

      this.visible = visible;
    },

    toggleAnimation(visible) {
      if (visible) this.play(); else this.stop();
    },

    async getAnimationData() {
      const { name } = this.project;
      const response = await axios.get(`${PROJECTS_API}?name=${name}`);

      if (!response || !response.data || !response.data.length) return;

      var animationData;

      try {
        animationData = JSON.parse(response.data[0].animation);
      } catch(err) {
        console.log('ERROR parsing animation data', err);
      }

      return animationData;
    },

    async loadAnimation() {
      if (this.animation) return;

      if (!this.status) {
        this.status = 'Loading';
        this.animationData = await this.getAnimationData();
        this.status = this.animationData ? 'Loaded' : 'Failed';
      }

      if (!this.animationData) return;

      this.animation = lottie.loadAnimation({
        container: this.$refs.animation,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        name: this.project.name,
        animationData: this.animationData,
        rendererSettings: {
          progressiveLoad: true
        }
      });

      if (this.playing) this.play();
    },

    unloadAnimation() {
      lottie.destroy(this.project.name);
      this.animation = null;
    },

    play() {
      this.playing = true;

      if (this.animation) this.animation.play();
      else this.loadAnimation();
    },

    stop() {
      this.playing = false;

      if (this.animation) this.animation.pause();
    },

    open(e) {
      // FIXME: ported from original script
      const { url = '#' } = this.project;

      if (url === '#') return false;

      window.open(url, '_blank');

      e.preventDefault();
    }
  },

  computed: {
    modifier() {
      const parts = [];

      const size = modifiers[this.project.size];
      const type = modifiers[this.project.type];

      if (size) parts.push(size);
      if (type) parts.push(type);

      return parts.join('-');
    },

    split() {
      return this.modifier === 'wide-white';
    }
  }
}
</script>
